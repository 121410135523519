import { devtools, persist, createJSONStorage } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { ProductStatus } from '@/features/BG'

export interface BGProductFilters {
  search?: string
  initial_date?: string
  final_date?: string
  type?: string
  category_name?: string
  logs_per_page?: number
  page?: number
  product_status?: ProductStatus
  integrated_with_vtex?: boolean
  is_kit?: boolean
}

interface BGProductFilterStore {
  filters: BGProductFilters
  resetFilters: () => void
  setFilters: (filters: BGProductFilters) => void
}

const bgProductFilterStore = createWithEqualityFn<BGProductFilterStore>()(
  devtools(
    persist(
      (set) => ({
        filters: {
          search: undefined,
          initial_date: undefined,
          final_date: undefined,
          type: undefined,
          category_name: undefined,
          logs_per_page: 20,
          page: 1,
          product_status: undefined,
        },

        setFilters: (filters) => {
          set(
            (oldState) => ({
              filters: { ...oldState.filters, ...filters },
            }),
            false,
            'set-filters',
          )
        },

        resetFilters: () => {
          set(
            {
              filters: {
                initial_date: undefined,
                final_date: undefined,
                type: undefined,
                category_name: undefined,
                product_status: undefined,
                logs_per_page: 20,
                page: 1,
              },
            },
            false,
            'reset-filters',
          )
        },
      }),
      { name: 'bg-product-filter-store', storage: createJSONStorage(() => sessionStorage) },
    ),
    { name: 'bg-product-filter-store' },
  ),
  shallow,
)

export const resetBGProductFilters = bgProductFilterStore.getState().resetFilters

export { bgProductFilterStore, bgProductFilterStore as useBGProductFilters }
