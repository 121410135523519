import { useNavigate } from 'react-router-dom'

import { DeleteOutlined, ExclamationCircleFilled, HistoryOutlined } from '@ant-design/icons'
import { Button, Modal, Image, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'

import * as S from './styles'

import anymarketLogo from '@/assets/logo-anymarket.svg'
import vtexLogo from '@/assets/logo-vtex-single.svg'
import {
  formatCurrency,
  formatDate,
  imgFallback,
  ModuleKey,
  RoleKey,
  truncateString,
  useRolePermissions,
} from '@/common'
import { BaseTable, TableAction } from '@/components'
import {
  Product,
  ProductStatus,
  formatProductType,
  BgStatusTags,
  hasHeroImage,
  canDeleteProduct,
} from '@/features/BG'
import { SelectProductActions } from '@/pages'

type BgListTableProps = {
  data: Product[]
  isLoading?: boolean
  handleSelectProduct: ({ type, product }: SelectProductActions) => void
}

export const BgListTable = ({ data, isLoading = false, handleSelectProduct }: BgListTableProps) => {
  const productPermissions = useRolePermissions(ModuleKey.PRODUCT, RoleKey.PRODUCT)

  const navigate = useNavigate()

  const columns: ColumnsType<Product> = [
    {
      title: '',
      key: 'tags',
      width: '0%',
      render: (_, product) => {
        return (
          <S.TagsColumn>
            {product?.sku?.is_kit && (
              <Tooltip color="#1E9493" title="Kit">
                <S.CircleTag style={{ backgroundColor: '#1E9493' }} />
              </Tooltip>
            )}

            {product?.is_b2b && (
              <Tooltip color="#4A91FD" title="B2B">
                <S.CircleTag style={{ backgroundColor: '#4A91FD' }} />
              </Tooltip>
            )}

            {product?.vtex_legacy && (
              <Tooltip color="#FF3366" title="Importado VTEX">
                <S.CircleTag style={{ backgroundColor: '#FF3366' }} />
              </Tooltip>
            )}
          </S.TagsColumn>
        )
      },
    },
    {
      title: '',
      dataIndex: ['partner_files', 'hero_image_partner_url'],
      key: 'hero_image',
      width: '5%',
      render: (_, product) => {
        return (
          <Image
            fallback={imgFallback}
            alt={`Hero image de ${product.name}`}
            src={
              hasHeroImage(product.status)
                ? product.partner_files.hero_image_partner_url
                : product?.sku?.sku_files?.[0]?.filename_url
            }
            style={{ width: '48px', height: '40px' }}
          />
        )
      },
    },
    {
      title: 'Tipo',
      dataIndex: 'product_type_name',
      key: 'type',
      width: '5%',
      render: (productType: string) => {
        return formatProductType(productType) || '--'
      },
    },
    {
      title: 'Família',
      dataIndex: 'family',
      key: 'family',
      width: '15%',
      render: (family: string, product) => {
        const productFamily = (family || product.family?.[0] || '--').toLowerCase()

        return (
          <S.BgFamily>
            {productFamily.length > 30 ? `${productFamily.slice(0, 30)}...` : productFamily}
          </S.BgFamily>
        )
      },
    },
    {
      title: 'Titulo',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: (name: string, product) => {
        const navigateRoute = product?.sku?.is_kit
          ? `/product/kit/${product.id}`
          : product?.status === 'EM RASCUNHO DO PRODUTO'
          ? `/create-product/${product.id}`
          : `/product/${product.id}`

        function handleClick() {
          if (
            (!productPermissions || !productPermissions?.canUpdate) &&
            product.status === 'EM RASCUNHO DO PRODUTO'
          ) {
            return
          }

          navigate(navigateRoute)
        }

        if (!name) {
          return <S.BgName onClick={handleClick}>{`${product.model} - ${product.ref_id}`}</S.BgName>
        }

        return <S.BgName onClick={handleClick}>{truncateString(name || '--', 40)}</S.BgName>
      },
    },
    {
      title: 'Categoria',
      dataIndex: 'category_name',
      key: 'name',
      width: '0%',
      render: (category) => <S.BgCategoryDate>{category || '--'}</S.BgCategoryDate>,
    },
    {
      title: 'Valor (R$)',
      dataIndex: 'sku',
      key: 'value',
      width: '25%',
      render: (_, product) => {
        return (
          <S.PriceContainer>
            <S.Price
              $hasMarketplacePrice={!!product?.sku?.price || false}
              $isUnpublished={
                product.status === 'DESPUBLICADO' || product.status === 'DESCONTINUADO'
              }
            >
              <Image src={vtexLogo} width={30} height={30} alt="Logo VTEX" preview={false} />
              {product?.sku?.price
                ? formatCurrency({ value: product?.sku?.price, type: 'currency' })
                : '--'}
            </S.Price>

            <S.Price
              $hasMarketplacePrice={!!product?.sku?.price_anymarket || false}
              $isUnpublished={
                product.status === 'DESPUBLICADO' || product.status === 'DESCONTINUADO'
              }
            >
              <Image
                src={anymarketLogo}
                width={30}
                height={30}
                alt="Logo AnyMarket"
                preview={false}
              />
              {product?.sku?.price_anymarket
                ? formatCurrency({ value: product?.sku?.price_anymarket, type: 'currency' })
                : '--'}
            </S.Price>
          </S.PriceContainer>
        )
      },
    },
    {
      title: 'Data',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: '5%',
      render: (date: string) => {
        return (
          <S.BgCategoryDate>
            {formatDate({ date: date, dateFormat: 'DD/MM/YYYY' }) || '--'}
          </S.BgCategoryDate>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (status: ProductStatus, product) => {
        const dropdownOptions = [
          {
            key: '1',
            label: (
              <S.ButtonContent>
                <Button
                  type="link"
                  icon={<HistoryOutlined />}
                  onClick={() => handleSelectProduct({ product, type: 'VIEW_HISTORY' })}
                >
                  Ver Histórico
                </Button>
              </S.ButtonContent>
            ),
          },
          (productPermissions?.isAdmin || productPermissions?.canDelete) &&
          canDeleteProduct(product.status)
            ? {
                key: '2',
                label: (
                  <S.ButtonContent>
                    <Button
                      danger
                      type="link"
                      icon={<DeleteOutlined />}
                      onClick={() => confirmDeletion(product)}
                      disabled={!canDeleteProduct(product.status)}
                    >
                      Excluir
                    </Button>
                  </S.ButtonContent>
                ),
              }
            : null,
        ]

        return (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <S.StatusContainer>
              <BgStatusTags status={status} />
            </S.StatusContainer>
            <TableAction dropdownOptions={dropdownOptions} disabled={product?.sku?.is_kit} />
          </div>
        )
      },
    },
  ]

  const handleBgNameLength = (name: string): string => {
    if (name === null) return '--'
    if (name.length > 55) {
      return `${name.slice(0, 48)}...`
    }

    return name
  }

  function confirmDeletion(product: Product) {
    Modal.confirm({
      title: `Deseja realmente excluir este produto?`,
      content: (
        <span>
          O produto <strong>{handleBgNameLength(product.name || product.model)}</strong> será
          excluido permanentemente.
        </span>
      ),
      centered: true,
      icon: <ExclamationCircleFilled style={{ color: '#FF0000' }} />,
      okText: 'Excluir',
      cancelText: 'Cancelar',
      footer: (_, { OkBtn, CancelBtn }) => (
        <S.ModalFooter>
          <CancelBtn />
          <OkBtn />
        </S.ModalFooter>
      ),
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          background: '#80c343',
        },
      },
      cancelButtonProps: {
        danger: true,
      },
      onOk() {
        handleSelectProduct({ product, type: 'DELETE_PRODUCT' })
      },
    })
  }

  return (
    <BaseTable
      dataSource={data}
      columns={columns}
      rowKey={(record, i) => `${record.id}-${i}`}
      loading={isLoading}
      pagination={false}
    />
  )
}
